import { useGoogleLogin } from "@react-oauth/google";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Oauth = () => {
  
  // const handleOauthResponse = async (response) => {

  // };
  // const googleLogin = useGoogleLogin({
  //   onSuccess: (credentialResponse) => handleOauthResponse(credentialResponse),
  // });
  return (
    <></>
    // <div className="d-flex justify-content-end social_icon">
  
    //   <span >
    //     <FontAwesomeIcon icon="fa-brands fa-google-plus-square" onClick={() => googleLogin()}/>
    //   </span>

    // </div>
  );
};

export default Oauth;
