// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  @media screen and (max-width:  768px) {
    .word-set.card {
      height: 50px;
      width: 90vw;
    
    }
  }

.word-set.card {
  height: 150px;
}

.word-set .card-text {
  height: 15px;
}


.word-set.card {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  background-color: rgba(241, 236, 236, 0.9) !important;
}

.word-set.card:hover {
  transform: scale(1.05);
}

.remove-btn {
  margin-left: auto;
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/WordSet/WordSet.css"],"names":[],"mappings":"EAAE;IACE;MACE,YAAY;MACZ,WAAW;;IAEb;EACF;;AAEF;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;;AAGA;EACE,sCAAsC;EACtC,eAAe;EACf,qDAAqD;AACvD;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,UAAU;AACZ","sourcesContent":["  @media screen and (max-width:  768px) {\n    .word-set.card {\n      height: 50px;\n      width: 90vw;\n    \n    }\n  }\n\n.word-set.card {\n  height: 150px;\n}\n\n.word-set .card-text {\n  height: 15px;\n}\n\n\n.word-set.card {\n  transition: transform 0.3s ease-in-out;\n  cursor: pointer;\n  background-color: rgba(241, 236, 236, 0.9) !important;\n}\n\n.word-set.card:hover {\n  transform: scale(1.05);\n}\n\n.remove-btn {\n  margin-left: auto;\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
