import SideBar from "../../components/SideBar/index";
import "./index.css"
const MainLayout = () => {
  return (
    <>
 <div className="main-layout">
      <SideBar />
    </div>
    </>
  );
};
export default MainLayout;
