// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.result-page {
    margin-left: auto;
    margin-right: auto;
}


.result-section {
    transition: all 0.4s ease;
}
.result-section.full-width {
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/RecordDetailPage/index.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;;AAGA;IACI,yBAAyB;AAC7B;AACA;IACI,WAAW;IACX,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".result-page {\n    margin-left: auto;\n    margin-right: auto;\n}\n\n\n.result-section {\n    transition: all 0.4s ease;\n}\n.result-section.full-width {\n    width: 50vw;\n    margin-left: auto;\n    margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
