// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media screen and (max-width:  768px) {
    .setup-box {
        padding: 1rem;
        max-width: 100%;
        margin: auto;
        background-color: var(--bg-color);
        border-radius: 1rem;
        height: 100%;

    }
}

@media screen and (min-width: 768px) {
    .setup-box {
        max-width: 100%;
        width: 40rem;
        margin: auto;
        padding: 1.5rem;
        background-color: var(--bg-color);
        border-radius: 1rem;
        height: 100%;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/index.css"],"names":[],"mappings":";AACA;IACI;QACI,aAAa;QACb,eAAe;QACf,YAAY;QACZ,iCAAiC;QACjC,mBAAmB;QACnB,YAAY;;IAEhB;AACJ;;AAEA;IACI;QACI,eAAe;QACf,YAAY;QACZ,YAAY;QACZ,eAAe;QACf,iCAAiC;QACjC,mBAAmB;QACnB,YAAY;IAChB;;AAEJ","sourcesContent":["\n@media screen and (max-width:  768px) {\n    .setup-box {\n        padding: 1rem;\n        max-width: 100%;\n        margin: auto;\n        background-color: var(--bg-color);\n        border-radius: 1rem;\n        height: 100%;\n\n    }\n}\n\n@media screen and (min-width: 768px) {\n    .setup-box {\n        max-width: 100%;\n        width: 40rem;\n        margin: auto;\n        padding: 1.5rem;\n        background-color: var(--bg-color);\n        border-radius: 1rem;\n        height: 100%;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
