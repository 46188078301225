// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width:  768px)  {
    .word-set-list {
        margin-top: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Setup/VocabularyBase/VocabularyBase.css"],"names":[],"mappings":"AAAA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["@media screen and (max-width:  768px)  {\n    .word-set-list {\n        margin-top: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
