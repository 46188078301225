// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5) !important;


}

.footer a {
  color: #a3de83;
}

.footer p {
  color: rgba(255, 255, 255, 0.3);
}

.footer .footer-heading {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 30px;
}

.footer .footer-heading .logo {
  color: #fff;
}

.footer .menu {
  margin-bottom: 30px;
}

.footer .menu a {
  color: rgba(255, 255, 255, 0.6);
  margin: 0 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.footer .ftco-footer-social li a {
  background: transparent;
  border: 1px solid #a3de83;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+CAA+C;;;AAGjD;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;EAC/B,cAAc;EACd,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;AAC3B","sourcesContent":[".footer {\n  padding: 10px;\n  background-color: rgba(0, 0, 0, 0.5) !important;\n\n\n}\n\n.footer a {\n  color: #a3de83;\n}\n\n.footer p {\n  color: rgba(255, 255, 255, 0.3);\n}\n\n.footer .footer-heading {\n  font-size: 30px;\n  color: #fff;\n  font-weight: 700;\n  margin-bottom: 30px;\n}\n\n.footer .footer-heading .logo {\n  color: #fff;\n}\n\n.footer .menu {\n  margin-bottom: 30px;\n}\n\n.footer .menu a {\n  color: rgba(255, 255, 255, 0.6);\n  margin: 0 10px;\n  text-transform: uppercase;\n  letter-spacing: 1px;\n}\n\n.footer .ftco-footer-social li a {\n  background: transparent;\n  border: 1px solid #a3de83;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
