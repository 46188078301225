// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-pagination {
    display: flex;
    justify-content: center;
    margin-top: 40px;

}

.paginationItemStyle {
    margin-left: 2px;
    margin-right: 2px;  
}

.pagination {
    border: 4px solid black;
}

.paginationItemStyle.page-item a {
    color: white;
    background-color:rgba(30, 31, 30, 0.9);
}

.pagination .page-item .page-link {
    color: white;
    background-color:rgba(30, 31, 30, 0.5);
}

.paginationItemStyle.active span {
    color: white !important;
    background-color: #6A5AE2 !important;
    z-index: -1;
}



`, "",{"version":3,"sources":["webpack://./src/components/Pagination/CustomPagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;;AAEpB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,sCAAsC;AAC1C;;AAEA;IACI,YAAY;IACZ,sCAAsC;AAC1C;;AAEA;IACI,uBAAuB;IACvB,oCAAoC;IACpC,WAAW;AACf","sourcesContent":[".custom-pagination {\n    display: flex;\n    justify-content: center;\n    margin-top: 40px;\n\n}\n\n.paginationItemStyle {\n    margin-left: 2px;\n    margin-right: 2px;  \n}\n\n.pagination {\n    border: 4px solid black;\n}\n\n.paginationItemStyle.page-item a {\n    color: white;\n    background-color:rgba(30, 31, 30, 0.9);\n}\n\n.pagination .page-item .page-link {\n    color: white;\n    background-color:rgba(30, 31, 30, 0.5);\n}\n\n.paginationItemStyle.active span {\n    color: white !important;\n    background-color: #6A5AE2 !important;\n    z-index: -1;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
