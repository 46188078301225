import Quiz from "../../components/Quiz/Quiz";
import "./index.css";


const QuizPage = () => {

  return (
    <>
    <Quiz/>
    </>
  );
};
export default QuizPage;
