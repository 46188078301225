// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.find-account-page .card{
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    width: 400px;
    background-color: rgba(0, 0, 0, 0.5) !important;
}


.find-account-page .social_icon span {
    font-size: 60px;
    margin-left: 10px;
    color: #FFC312;
}

.find-account-page .social_icon span:hover {
    color: white;
    cursor: pointer;
}

.find-account-page.card-header h3 {
    color: white;
}

.find-account-page .social_icon {
    position: absolute;
    right: 20px;
    top: -45px;
}

.find-account-page .input-group-prepend span {
    width: 50px;
    height: 100%;
    background-color: #FFC312;
    color: black;
    border: 0 !important;
    border-radius: 0% !important;
}

.find-account-page input:focus {
    outline: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;

}


.find-account-page .find_btn {
    color: black;
    background-color: #FFC312;
    width: 100%;
}

.find-account-page .find_btn:hover {
    color: black;
    background-color: white;
}

.find-account-page .links {
    color: white;
}

.find-account-page .links a {
    margin-left: 4px;
}`, "",{"version":3,"sources":["webpack://./src/pages/FindAccountPage/index.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,+CAA+C;AACnD;;;AAGA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,UAAU;AACd;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,oBAAoB;IACpB,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;IAC3B,8BAA8B;;AAElC;;;AAGA;IACI,YAAY;IACZ,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["\n.find-account-page .card{\n    height: 100%;\n    margin-top: auto;\n    margin-bottom: auto;\n    width: 400px;\n    background-color: rgba(0, 0, 0, 0.5) !important;\n}\n\n\n.find-account-page .social_icon span {\n    font-size: 60px;\n    margin-left: 10px;\n    color: #FFC312;\n}\n\n.find-account-page .social_icon span:hover {\n    color: white;\n    cursor: pointer;\n}\n\n.find-account-page.card-header h3 {\n    color: white;\n}\n\n.find-account-page .social_icon {\n    position: absolute;\n    right: 20px;\n    top: -45px;\n}\n\n.find-account-page .input-group-prepend span {\n    width: 50px;\n    height: 100%;\n    background-color: #FFC312;\n    color: black;\n    border: 0 !important;\n    border-radius: 0% !important;\n}\n\n.find-account-page input:focus {\n    outline: 0 0 0 0 !important;\n    box-shadow: 0 0 0 0 !important;\n\n}\n\n\n.find-account-page .find_btn {\n    color: black;\n    background-color: #FFC312;\n    width: 100%;\n}\n\n.find-account-page .find_btn:hover {\n    color: black;\n    background-color: white;\n}\n\n.find-account-page .links {\n    color: white;\n}\n\n.find-account-page .links a {\n    margin-left: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
