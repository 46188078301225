// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-area {
    position: relative;
    /* Needed for absolute positioning of children */
}

.preloader-overlay {
    position: fixed; /* Fixed positioning relative to the viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Set height to 100% of viewport height */
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; /* Ensure it's above other elements */
}`, "",{"version":3,"sources":["webpack://./src/components/PreLoader/PreLoader.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gDAAgD;AACpD;;AAEA;IACI,eAAe,EAAE,+CAA+C;IAChE,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa,EAAE,0CAA0C;IACzD,0CAA0C,EAAE,gCAAgC;IAC5E,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW,EAAE,qCAAqC;AACtD","sourcesContent":[".content-area {\n    position: relative;\n    /* Needed for absolute positioning of children */\n}\n\n.preloader-overlay {\n    position: fixed; /* Fixed positioning relative to the viewport */\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100vh; /* Set height to 100% of viewport height */\n    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 10; /* Ensure it's above other elements */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
