// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* For screens larger than or equal to 768px */
@media (min-width: 768px) {
  .quiz-history-container {
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
  }

  .quiz-history-table {
    width: 100%;
    border-collapse: collapse;
  }

  .quiz-history-table th,
  .quiz-history-table td {
    text-align: left;
    padding: 8px;
  }

  .quiz-history-table th {
    background-color: #4e5d6c;
    color: white;
  }

  .quiz-history-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}
/* For screens smaller than or equal to 768px */
@media (max-width:  768px) {
  .quiz-history-container {
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
  }

  .quiz-history-table {
    width: 100%;
    border-collapse: collapse;
  }

  .quiz-history-table th,
  .quiz-history-table td {
    text-align: left;
    padding: 8px;
  }

  .quiz-history-table th {
    background-color: #4e5d6c;
    color: white;
  }

  .quiz-history-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Record/Record.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;EACE;IACE,0CAA0C,EAAE,2BAA2B;IACvE,kBAAkB;IAClB,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;;IAEE,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B;AACF;AACA,+CAA+C;AAC/C;EACE;IACE,0CAA0C,EAAE,2BAA2B;IACvE,kBAAkB;IAClB,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;;IAEE,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["/* For screens larger than or equal to 768px */\n@media (min-width: 768px) {\n  .quiz-history-container {\n    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */\n    border-radius: 8px;\n    padding: 20px;\n    margin-top: 20px;\n  }\n\n  .quiz-history-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n\n  .quiz-history-table th,\n  .quiz-history-table td {\n    text-align: left;\n    padding: 8px;\n  }\n\n  .quiz-history-table th {\n    background-color: #4e5d6c;\n    color: white;\n  }\n\n  .quiz-history-table tr:nth-child(even) {\n    background-color: #f2f2f2;\n  }\n}\n/* For screens smaller than or equal to 768px */\n@media (max-width:  768px) {\n  .quiz-history-container {\n    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */\n    border-radius: 8px;\n    padding: 20px;\n    margin-top: 20px;\n  }\n\n  .quiz-history-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n\n  .quiz-history-table th,\n  .quiz-history-table td {\n    text-align: left;\n    padding: 8px;\n  }\n\n  .quiz-history-table th {\n    background-color: #4e5d6c;\n    color: white;\n  }\n\n  .quiz-history-table tr:nth-child(even) {\n    background-color: #f2f2f2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
