// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.word.card {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
    overflow-y: auto;
    background-color: rgba(241, 236, 236, 0.9) !important;
}

/* Word.css */


.word.card .card-body {
    font-family: Arial, sans-serif;
}

.word.card h3 {
    color: #333;
}

.word.card .pronunciation {
    margin-top: 10px;
}

.word.card h4 {
    color: #555;
}

.word.card ul {
    list-style-type: disc; /* Use 'disc' for filled circles, 'circle' for empty circles, or 'square' for filled squares */
    padding: 0;
    margin-left: 40px;
}

.word.card li {
    margin-bottom: 5px;

}

/* Add more styles as needed for your specific design */`, "",{"version":3,"sources":["webpack://./src/components/WordSet/Word/Word.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,YAAY;IACZ,gBAAgB;IAChB,qDAAqD;AACzD;;AAEA,aAAa;;;AAGb;IACI,8BAA8B;AAClC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB,EAAE,8FAA8F;IACrH,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;;AAEtB;;AAEA,uDAAuD","sourcesContent":[".word.card {\n    width: 70%;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 30px;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    height: 100%;\n    overflow-y: auto;\n    background-color: rgba(241, 236, 236, 0.9) !important;\n}\n\n/* Word.css */\n\n\n.word.card .card-body {\n    font-family: Arial, sans-serif;\n}\n\n.word.card h3 {\n    color: #333;\n}\n\n.word.card .pronunciation {\n    margin-top: 10px;\n}\n\n.word.card h4 {\n    color: #555;\n}\n\n.word.card ul {\n    list-style-type: disc; /* Use 'disc' for filled circles, 'circle' for empty circles, or 'square' for filled squares */\n    padding: 0;\n    margin-left: 40px;\n}\n\n.word.card li {\n    margin-bottom: 5px;\n\n}\n\n/* Add more styles as needed for your specific design */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
