// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `    .scrollable-col {
        max-height: 100vh;
        overflow-y: auto;
    }

    

    .given-text {
        display: block;
        background-color: white;
        padding: 40px;
    }

    @media screen and (max-width:  768px) {
        .result.given-text {
            display: none;
        }   
        .given-text {
            display: none;
            
        }
    }



   



    .progress-timer {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        border-radius: 6%;
        position: relative;
        overflow: hidden;
    }

    .progress-timer.time {
        width: 120px;
        height: 70px;
        background: white
    }`, "",{"version":3,"sources":["webpack://./src/pages/QuizPage/index.css"],"names":[],"mappings":"IAAI;QACI,iBAAiB;QACjB,gBAAgB;IACpB;;;;IAIA;QACI,cAAc;QACd,uBAAuB;QACvB,aAAa;IACjB;;IAEA;QACI;YACI,aAAa;QACjB;QACA;YACI,aAAa;;QAEjB;IACJ;;;;;;;;IAQA;QACI,YAAY;QACZ,aAAa;QACb,aAAa;QACb,mBAAmB;QACnB,uBAAuB;QACvB,cAAc;QACd,iBAAiB;QACjB,kBAAkB;QAClB,gBAAgB;IACpB;;IAEA;QACI,YAAY;QACZ,YAAY;QACZ;IACJ","sourcesContent":["    .scrollable-col {\n        max-height: 100vh;\n        overflow-y: auto;\n    }\n\n    \n\n    .given-text {\n        display: block;\n        background-color: white;\n        padding: 40px;\n    }\n\n    @media screen and (max-width:  768px) {\n        .result.given-text {\n            display: none;\n        }   \n        .given-text {\n            display: none;\n            \n        }\n    }\n\n\n\n   \n\n\n\n    .progress-timer {\n        width: 100px;\n        height: 100px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        margin: 0 auto;\n        border-radius: 6%;\n        position: relative;\n        overflow: hidden;\n    }\n\n    .progress-timer.time {\n        width: 120px;\n        height: 70px;\n        background: white\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
